<template>
  <div class="row">
    <modal
        name="open_to_settings_table"
        :adaptive="true"
        height="auto"
        width="300"
    >
      <div class="row mx-0 py-7">
        <div class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold">
          <div class="col-6 text-center">{{ $t('general.colum_name') }}</div>
          <div class="col-6 text-center">{{ $t('general.add') }}</div>
        </div>
        <div class="col-12  mx-0 py-2 text-xl">
          <draggable class="list-group py-4" tag="ul" v-model="fieldsToViewEdit" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false">
            <transition-group type="transition" :name="'flip-list'">
              <div class="col-12 list-group-item w-300px d-flex justify-content-between " v-for="element in fieldsToViewEdit" :key="element.sort">
                <span class="col-6"> {{$t(element.name)}}</span>

                <span class="col-6 d-flex justify-content-center">
                  <input type="checkbox" style="font-size: 10px " v-model="element.type_status"></span>
              </div>
            </transition-group>
          </draggable>
          <button
              @click="closeModal"
              class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5">{{ $t('general.give_up').toUpperCase() }}
          </button>
          <button
              @click="onSubmitToSaveSettingTable"

              class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer">{{ $t('general.save') }}
          </button>
        </div>

      </div>
    </modal>
    <div class="col-md-12">
      <div class="row py-3 px-4" id="filter-card" v-if="filters">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="onHitBottom(null)">
            <div class="row align-items-end py-0 my-0 mx-0">
            <div class="col-lg-6 col-md-5 col-sm-12 my-2 row px-0 mx-0">
              <input-filter
                  :helper-text="$t('general.search')"
                  title="general.search"
                  :placeholder="$t('general.search_filter')"
                  :model.sync="filters.search"
              ></input-filter>
            </div>
            <div class="col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
              <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                <date-filter
                    :helper-text="$t('general.start_date')"
                    title="general.start_date"
                    :model.sync="filters.start_date"
                    name="start_time">
                </date-filter>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                <date-filter
                    :helper-text="$t('general.end_date')"
                    title="general.end_date"
                    :model.sync="filters.end_date"
                    name="end_date">
                </date-filter>
              </div>
            </div>
            <div
                class="col-xl-2 col-lg-2 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important;">
              <div class="col-6 pr-0">
                <button @click="onHitBottom(null)"
                        class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                    $t('general.filter')
                  }}
                </button>
              </div>
              <div class="col-6 pr-0">
                <button @click="exportTimeSheet"
                        class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">
                  {{ $t('general.export_to_excel') }}
                </button>
              </div>
              <div class="col-6 pr-0">
                <button @click="resetFilters"
                        class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                  {{ $t('general.clear') }}
                </button>
              </div>
              <div class="col-6 pr-0">
                <button  @click="exportPackingListPdf()" class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                  {{ $t('general.export_to_list') }}
                </button>
              </div>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <dashboard-box :title="$t('time_sheet.self')">
        <template v-slot:toolbar>
        </template>
        <template v-slot:preview>
          <div class="fixed"  style="position: fixed !important; top: 220px; right: 15px;">
            <div class="w-60px h-60px" @click="openPopToSettingTable">
              <span v-html="getIconByKey('icons.waybill.edit', {
              class: 'w-40px h-40px d-inline-block object-fill cursor-pointer'
             })">
              </span>
            </div>
          </div>
          <data-table
              tableVariant="''"
              :hover="false"
              :outlined="false"
              :striped="false"
              :no-border-collapse="true"
              :borderless="true"
              :bordered="false"
              @hitBottom="onHitBottom"
              @rowClicked="onRowClicked"
              :items="formattedItems"
              :fields.sync="tableFields"
              :page.sync="page"
              :perPage.sync="perPage"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              @onChangeSearchFilter="onChangeSearchFilter"

          ></data-table>
          <div class="fixed" style="bottom: 20px; right: 40px;"  v-if="isUserGranted('TimeSheet', ['create'], false)">
            <div class="w-60px h-60px" @click="openCreatePage">
              <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                })">
                </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations} from 'vuex'
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";

import {
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  FILTER,
  SET_FILTER,
  LOADING,
  EXPORT, SET_LOADING, UPDATE_ITEM_BY_ID,
  EXPORT_PDF
} from "@/core/services/store/time-sheet/time_sheet.module";
import {LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import * as _ from "lodash";
import draggable from 'vuedraggable'

export default {
  name: "TimeSheetIndex",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect,
    draggable
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      let fields = [];
      let self = this;
      for (let [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
        if (value.status === true){
          let options = [];
          fields.push({
            'key' : key,
            'label' : this.$t(value.name) ,
            'sortable' : value.sortable,
            'sort' : value.sort,
            'class' : "text-center border border-top-0 border-left-0",
            'tdClass' : "cursor-pointer",
            'searchable' : value.searchable,
            'searchable_type' : value.searchable_type,
            'searchable_options' : options,
          })
        }
      }
      return _.sortBy(fields, ['sort']);
    },
    formattedItems() {
      if(!this.items || this.items.data === null || this.items.data === undefined) return [];
      let temp = [];
      this.items.data.forEach(item => {
        temp.push({
          'id': item.id,
          'table_image': item.image_link,
          'date': moment(item.date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
          'supplier_company': item.supplier_company.name,
          'reference_code': item.reference_code,
          'collection_number': item.collection_number,
          'responsible_person': item.responsible_person,
          'quality': item.quality,
          'composition': item.composition,
          'width': item.width,
          'weight': item.weight,
        })
      })
      return temp;
    },
  },
  data() {
    return {
      // Filters & pagination
      editable: true,
      isDragging: false,
      delayedDragging: false,
      fieldsToViewEdit:[],
      paginate: [],
      page: 1,
      perPage: 25,
      sort: "desc",
      order: "id",
      items: [],
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields:{},
        }
      ],
      baseUrl: 'api/time-sheets',
    };
  },
  methods: {
    ...mapMutations({
      'setFilter': SET_FILTER,
    }),
    onChangeSearchFilter(key, value) {
      this.$set(this.filters, key, value);
    },
    openCreatePage(){
      this.$router.push({name: 'time-sheet.create', params: {type: 1}, query: this.$route.query})
    },
    exportPackingListPdf() {
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'order', this.order);
      this.$set(filters, 'sort', this.sort);

      this.$store.dispatch(EXPORT_PDF, {
        filters: filters
      }).then(result => {
        if (result.status) {
          let fileURL = window.URL.createObjectURL(new Blob([result.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let day = moment().format('YYYYMMDDHHMMSS');
          let fileName = 'time_sheet' + day + '.pdf';
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      })
    },
    getTableItem(){
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/model_table',
        filters: {
          model_name: 'time_sheet'
        },
        acceptPromise: true,
        showLoading: false,
      }).then(result => {
        if(result.status) {
          for(let i = 0; i < result.data.length; i++)
          {
            self.modelTableItem = result.data;
            self.modelTableItem[i].table_fields = JSON.parse(result.data[i].table_fields)
          }
          for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
            self.fieldsToViewEdit.push({
              'name' : this.$t(value.name) ,
              'sort' : value.sort,
              'type' : value.type,
              'type_status' : value.status,
              'sortable' : value.sortable,
              'key' : key,
              'searchable' : !!value.searchable,
              'searchable_type' : value.searchable_type,
              'searchable_options': value.searchable_options,
            })
            self.fieldsToViewEdit =  _.sortBy(self.fieldsToViewEdit, ['sort']);
          }
        }
      });
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort((one, two) => {
        return one.sort - two.sort;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
          (!relatedElement || !relatedElement.status) && !draggedElement.status
      );
    },
    openPopToSettingTable(){
      this.$modal.show('open_to_settings_table')
    },
    closeModal(){
      this.$modal.hide('open_to_settings_table');
    },
    onSubmitToSaveSettingTable(){
      let self = this;
      let json = {};

      for (let i = 0 ; i < this.fieldsToViewEdit.length ; i++) {
        json[this.fieldsToViewEdit[i].key] = {
          name: this.fieldsToViewEdit[i].name,
          type : this.fieldsToViewEdit[i].type,
          status : this.fieldsToViewEdit[i].type_status,
          sortable : this.fieldsToViewEdit[i].sortable,
          sort : i ,
          searchable : !!this.fieldsToViewEdit[i].searchable,
          searchable_type : this.fieldsToViewEdit[i].searchable_type,
        };
      }

      let contents = {
        model_name : 'time_sheet',
        table_fields : json,
      }
      let payload = {
        url:'api/model_table/' + this.modelTableItem[0].id,
        id: this.id,
        contents: contents ,
      }
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then(result => {
        if (result.status) {
          this.$modal.hide('open_to_settings_table');
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          this.fieldsToViewEdit = [];
          this.getTableItem();
        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    onRowClicked(item, index, event) {
      if(!this.isUserGranted('TimeSheet', ['update', 'view'])) {
        return false;
      }

      this.$router.push({name: 'time-sheet.edit', params: {id: this.formattedItems[index].id,}});
    },
    onHitBottom($state) {
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'order', this.order);
      this.$set(filters, 'sort', this.sort);

      this.$store.dispatch(HANDLE_INFINITE_SCROLL, {
        url: this.baseUrl,
        filters: filters,
        '$state': $state,
      }).then(result => {
        if(result.status) {
          self.items = result.data;
          if($state) {
            $state.loaded();
          }
        }else{
          if($state) {
            $state.complete();
          }
        }
      })
    },
    resetFilters() {
      this.setFilter({
        page: 1,
        per_page: 25,
        search: "",
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        order: "",
        sort: ""
      })
    },
    exportTimeSheet() {
      this.$store.dispatch(EXPORT, {}).then(result => {
        if(result.status) {
          let fileURL = window.URL.createObjectURL(new Blob([result.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let day = moment().format('YYYYMMDDHHMMSS');
          let fileName = 'time_sheet' + day + '.xlsx';
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      })
    }
  },
  created() {
    this.$store.commit(SET_LOADING, false);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("time_sheet.self")}
    ]);
    if (!this.filter) {
      this.resetFilters();
    }

    this.getTableItem();

    if(!this.isUserGranted('TimeSheet', ['viewAny', 'view'])) {
      return false;
    }

    setTimeout(this.onHitBottom, 500);
  },
  watch: {
    sort() {
      this.onHitBottom();
    },
    order() {
      this.onHitBottom();
    },
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
}
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>
